<template>
  <div
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
    "
  >
    <v-sheet width="450" class="mx-auto">
      <h1 class="text-center mb-4" style="color: #ffffff">CDP Hosting</h1>
      <h3 class="text-center mb-4" style="color: #ffffff">Contact Us</h3>
      <v-form @submit.prevent="submitForm">
        <v-text-field
          v-model="Name"
          :rules="nameRules"
          label="Name"
          outlined
          dense
          style="color: #ffffff"
        ></v-text-field>
        <v-text-field
          v-model="Email"
          :rules="emailRules"
          label="Email"
          outlined
          dense
          style="color: #ffffff"
        ></v-text-field>
        <v-textarea
          v-model="Subject"
          :rules="subjectRules"
          label="Subject"
          outlined
          dense
          style="color: #ffffff"
        ></v-textarea>
        <v-select
          v-model="select"
          :items="items"
          :rules="itemRules"
          label="Reason"
          required
          outlined
          dense
          style="color: #ffffff"
        ></v-select>
        <v-alert v-if="alertMessage" :type="alertType" outlined dense>
          {{ alertMessage }}
        </v-alert>
        <v-btn type="submit" block class="mt-2" :disabled="!canSubmit()"
          >Submit</v-btn
        >
      </v-form>
    </v-sheet>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  name: "contactForm",

  data: () => ({
    Name: "",
    Email: "",
    Subject: "",
    select: null,
    items: ["Enquiry", "Support"],
    nameRules: [(v: string) => !!v || "Name is required"],
    emailRules: [
      (v: string) => !!v || "Email is required",
      (v: string) => /.+@.+/.test(v) || "Enter a valid email",
    ],
    subjectRules: [(v: string) => !!v || "Subject is required"],
    itemRules: [(v: string) => !!v || "Item is required"],
    lastSubmissionTime: 0,
    submissionLimit: 5, // Allow 5 submissions in a 5-minute window
    submissionInterval: 5 * 60 * 1000, // 5 minutes in milliseconds
    submissionCounter: 0, // Track number of submissions
    alertType: null as string | null,
    alertMessage: null as string | null,
  }),

  methods: {
    canSubmit() {
      const now = Date.now();
      const timeSinceLastSubmission = now - this.lastSubmissionTime;
      const hasEnoughTimePassed =
        !this.lastSubmissionTime ||
        timeSinceLastSubmission > this.submissionInterval;
      const canSubmit =
        hasEnoughTimePassed || this.submissionCounter < this.submissionLimit;

      if (!canSubmit) {
        console.log(
          "Submission limit reached. Please wait a moment before submitting again."
        );
      }

      return canSubmit;
    },

    submitForm() {
      this.lastSubmissionTime = Date.now(); // Update the last submission time

      if (
        this.Name &&
        this.Email &&
        this.Subject &&
        this.select &&
        this.canSubmit()
      ) {
        axios
          .post("https://api.cdphosting.co.uk/send-email", {
            Name: this.Name,
            Email: this.Email,
            Subject: this.Subject,
            Item: this.select,
          })
          .then((response) => {
            console.log(response.data); // Log the response data to see what it contains

            if (response.data.message === "Email sent successfully") {
              this.showAlert("success", "Email sent successfully!");
            } else {
              this.showAlert("error", "Unexpected response from server");
            }
          })
          .catch((error) => {
            console.error(error);
            this.showAlert(
              "error",
              "Failed to send email. Please try again later."
            );
          });
      } else {
        this.showAlert(
          "error",
          "Please fill in all fields or wait a moment before submitting again."
        );
      }
    },

    showAlert(type: string, message: string): void {
      this.alertType = type;
      this.alertMessage = message;
    },
  },
});
</script>

<style>
.v-sheet {
  background-color: #455a64; /* Dark background color */
  padding: 20px;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow effect */
}

.v-text-field,
.v-textarea,
.v-select {
  margin-bottom: 20px;
  width: 100%;
}

/* Style submit button */
.v-btn {
  background-color: #1976d2; /* Blue button color */
  color: #ffffff; /* White text color */
  border-radius: 5px;
}

/* Hover effect on submit button */
.v-btn:hover {
  background-color: #1565c0; /* Darker blue on hover */
}

.v-messages {
  font-weight: bold;
}

.v-label {
  font-weight: bold;
}
</style>
