<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",

  data() {
    return {
      //
    };
  },
});
</script>

<style>
#app {
  overflow: hidden; /* This will hide the scrollbar */
  height: 100vh; /* This ensures the content takes up the full viewport height */
}
.v-main {
  background-color: rgb(5, 77, 77);
}
</style>
